import styled from 'styled-components';

import { Button } from '@/core/components/Button';
import { BodySmall } from '@/core/components/Typography/Typography.styles';

import { breakpoint } from '@/themes/breakpoints';

export const Links = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    justify-content: start;
    width: max-content;
    font-size: 24px;
  }

  @media ${breakpoint.sm} {
    flex-direction: row;
    align-items: center;
    gap: 24px;
    margin-top: 0;

    a {
      font-size: 14px;
    }
  }

  @media ${breakpoint.md} {
    gap: 32px;
  }
`;

export const LanguageButton = styled(Button)`
  ${BodySmall};
  height: 40px;
  border-radius: 30px;
  padding: 11px 15px;
  text-transform: none;

  &[data-userway-s3-2-styled="true"] {
    path {
      fill: none;
      stroke: var(--user-way-dark-contrast-color-2);
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;

  @media ${breakpoint.sm} {
    width: auto;
    margin-top: 0;
    gap: 32px;
  }
`;
