import { IconProps } from './Icon.types';

export const Hamburger = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6.5H18V7.5H6V6.5ZM6 11.5H18V12.5H6V11.5ZM18 16.5H6V17.5H18V16.5Z"
      fill={fill}
    />
  </svg>
);
