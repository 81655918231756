import React, {
  ElementType,
  HTMLProps,
  useMemo,
  useState,
} from 'react';

import { useGlobalContext } from '@/core/contexts/GlobalContext/GlobalContext';

import { theme } from '@/themes/main';

import {
  IconWrapper,
  StyledButton,
} from './Button.styles';
import {
  ButtonVariant,
  CustomButtonProps,
} from './Button.types';
import { getIconColor } from './Button.helpers';

export type ButtonProps<T extends ElementType = 'button'> = CustomButtonProps<T> & HTMLProps<T>

export const Button = <T extends ElementType = 'button'>({
  type = 'button',
  onClick,
  variant = ButtonVariant.PRIMARY,
  label,
  isDisabled,
  startIcon,
  endIcon,
  renderAs,
  ...props
}: ButtonProps<T>) => {
  const { isTouchDevice } = useGlobalContext();

  const [
    isHover,
    setIsHover,
  ] = useState(false);

  const iconColor = useMemo(
    () => getIconColor({
      isDisabled,
      isHover,
      theme,
      variant,
    }),
    [
      variant,
      isHover,
      isDisabled,
    ]
  );

  return (
    <StyledButton
      type={type}
      onClick={onClick}
      $variant={variant}
      disabled={isDisabled}
      $startIcon={startIcon}
      $endIcon={endIcon}
      onMouseEnter={() => setIsHover(!isTouchDevice)}
      onMouseLeave={() => setIsHover(false)}
      as={renderAs as any}
      {...props}
    >
      { startIcon && (
        <IconWrapper isGhost={variant === ButtonVariant.GHOST}>
          { React.cloneElement(startIcon, {
            fill: iconColor,
            size: 32,
          }) }
        </IconWrapper>
      )}
      { label && <span>{label}</span> }
      { endIcon && (
        <IconWrapper isGhost={variant === ButtonVariant.GHOST}>
          { React.cloneElement(endIcon, {
            fill: iconColor,
            size: 32,
          }) }
        </IconWrapper>
      )}
    </StyledButton>
  );
};
