import { FormattedMessage } from 'react-intl';

import { navigationMessages } from '@/core/messages/navigation.messages';

import { Wrapper } from './Copyright.styles';

export const Copyright = () => (
  <Wrapper>
    <p>{`© ${new Date().getFullYear()} Reem Al Hashimy`}</p>
    <p>
      <FormattedMessage {...navigationMessages.allRightsReserved} />
    </p>
  </Wrapper>
);
