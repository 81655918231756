import { IconProps } from './Icon.types';

export const Send = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.75 2.25L2.25 11.25L11.25 13.125M21.75 2.25L14.25 21.75L11.25 13.125M21.75 2.25L11.25 13.125"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
