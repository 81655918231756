import styled from 'styled-components';

import { margins } from '@/core/components/Grid';

import {
  breakpoint,
  headerSizes,
} from '@/themes/breakpoints';

export const HeaderWrapper = styled.div`
  max-width: 1840px;
  margin: 0 ${headerSizes.phone.margin}px;

  @media ${breakpoint.sm} {
    ${margins(headerSizes.tablet.margin)};
  }

  @media ${breakpoint.md} {
    ${margins(headerSizes.desktop.margin)};
  }

  @media ${breakpoint.lg} {
    margin: auto;
  }
`;
