/* eslint-disable sort-keys */
export type MediaBreakpoint = typeof breakpoint[keyof typeof breakpoint];

export const sizes = {
  phone: {
    columns: 4,
    gap: 8,
    margin: 16,
    minWidth: 375,
  },
  tablet: {
    columns: 8,
    gap: 24,
    margin: 32,
    minWidth: 768,
  },
  desktop: {
    columns: 12,
    gap: 24,
    margin: 80,
    minWidth: 1280,
  },
  desktopLarge: {
    columns: 12,
    gap: 24,
    margin: 80,
    minWidth: 1920,
  },
} as const;

export const headerSizes = {
  ...sizes,
  desktop: {
    ...sizes.desktop,
    margin: 40,
  },
  desktopLarge: {
    ...sizes.desktopLarge,
    margin: 40,
  },
} as const;

export const breakpoint = {
  xs: `(min-width: ${sizes.phone.minWidth}px)`,
  sm: `(min-width: ${sizes.tablet.minWidth}px)`,
  md: `(min-width: ${sizes.desktop.minWidth}px)`,
  lg: `(min-width: ${sizes.desktopLarge.minWidth}px)`,
} as const;

