import Link from 'next/link';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { LinkButton } from '@/core/components/LinkButton/LinkButton';
import { Lang } from '@/core/components/Icons';
import { ButtonVariant } from '@/core/components/Button/Button.types';
import { globalMessages } from '@/core/messages/global.messages';
import { routePaths } from '@/core/constants/routes';
import { useGlobalContext } from '@/core/contexts/GlobalContext/GlobalContext';
import { NavigationItem } from '@/core/interfaces/common';

import { isOutsideLink } from '@/utils/helpers';

import {
  LanguageButton,
  Links,
  Wrapper,
} from './Navigation.styles';

type NavigationProps = {
  navigationItems?: Array<NavigationItem>;
}

export const Navigation = ({
  navigationItems,
}: NavigationProps) => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { locale } = useIntl();
  const { articleSlugs } = useGlobalContext();

  const {
    pathname,
    asPath,
    query,
  } = router;

  const notSelectedLocale = useMemo(() => (locale === 'en' ? 'ar-AE' : 'en'), [locale]);

  const changeLocale = () => {
    const otherLocaleSlug = articleSlugs[notSelectedLocale as keyof typeof articleSlugs];
    const as = otherLocaleSlug ? `/${routePaths.vision}/${otherLocaleSlug}` : asPath;

    router.push({
      pathname,
      query,
    }, as, { locale: notSelectedLocale });
  };

  return (
    <Wrapper>
      <Links>
        {navigationItems?.map(({
          id,
          link,
          name,
        }) => (
          <LinkButton
            isActive={router.pathname.replace(/-/g, '').toLocaleLowerCase() === link.slice(1).toLocaleLowerCase()}
            key={id}
            renderAs={Link}
            label={name}
            href={link}
            target={isOutsideLink(link) ? '_blank' : '_self'}
          />
        ))}
      </Links>
      <LanguageButton
        variant={ButtonVariant.SECONDARY}
        endIcon={<Lang />}
        label={formatMessage(globalMessages[notSelectedLocale as keyof typeof globalMessages])}
        onClick={changeLocale}
      />
    </Wrapper>
  );
};
