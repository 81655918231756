import { IconProps } from './Icon.types';

export const ArrowExternal = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M17.0948 14.4744V7.40332C17.0948 7.27071 17.0421 7.14353 16.9483 7.04977C16.8545 6.956 16.7274 6.90332 16.5948 6.90332H9.5237L9.5237 7.90332L15.3874 7.90332L7.04883 16.2419L7.75593 16.949L16.0948 8.61021V14.4744H17.0948Z"
    />
  </svg>
);
