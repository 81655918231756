import { useTheme } from 'styled-components';

import { MarginWrapper } from '@/core/components/MarginWrapper';
import { Logo } from '@/core/components/Logo';
import { NavigationItem } from '@/core/interfaces/common';

import {
  LogoWrapper,
  Wrapper,
} from './Footer.styles';
import { Copyright } from './Copyright/Copyright';
import { Navigation } from './Navigation/Navigation';

type FooterProps = {
  navigationItems: Array<NavigationItem>;
}

export const Footer = ({
  navigationItems,
}: FooterProps) => {
  const theme = useTheme();

  return (
    <Wrapper>
      <MarginWrapper>
        <LogoWrapper>
          <Logo fill={theme.colors.white} />
        </LogoWrapper>
        <Navigation navigationItems={navigationItems} />
        <Copyright />
      </MarginWrapper>
    </Wrapper>
  );
};
