import { createGlobalStyle } from 'styled-components';
import localFont from '@next/font/local';

import {
  BodyLarge,
  BodyMedium,
  BodySmall,
  Button,
  Caption,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
} from '@/core/components/Typography/Typography.styles';

import { Theme } from './themes';

export const NotoSans = localFont({
  src: [
    {
      path: './fonts/NotoSans-Regular.ttf',
      style: 'normal',
      weight: '300',
    },
    {
      path: './fonts/NotoSans-Regular.ttf',
      style: 'normal',
      weight: '400',
    },
    {
      path: './fonts/NotoSans-SemiBold.ttf',
      style: 'normal',
      weight: '500',
    },
    {
      path: './fonts/NotoSans-SemiBold.ttf',
      style: 'normal',
      weight: '600',
    },
    {
      path: './fonts/NotoSans-Bold.ttf',
      style: 'normal',
      weight: '700',
    },
    {
      path: './fonts/NotoSans-Bold.ttf',
      style: 'normal',
      weight: '800',
    },
  ],
  variable: '--executive-website-regular-font-family',
});

export const Amiri = localFont({
  src: [
    {
      path: './fonts/Amiri-Regular.ttf',
      style: 'normal',
      weight: '300',
    },
    {
      path: './fonts/Amiri-Regular.ttf',
      style: 'normal',
      weight: '400',
    },
    {
      path: './fonts/Amiri-Italic.ttf',
      style: 'italic',
      weight: '300',
    },
    {
      path: './fonts/Amiri-Italic.ttf',
      style: 'italic',
      weight: '400',
    },
  ],
  variable: '--executive-website-heading-font-family',
});

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  :root {
    --executive-website-regular-font-family: ${NotoSans.style.fontFamily};
    --executive-website-heading-font-family: ${Amiri.style.fontFamily};
    --max-margin-wrapper-width: 1760px;
    --user-way-dark-contrast-color: rgb(252, 255, 60);
    --user-way-dark-contrast-color-2: rgb(80, 208, 160);
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    background: ${({ theme: { backgroundColor } }) => backgroundColor};
    direction: ${({ theme: { direction } }) => direction};
    font-size: 14px;
    font-family: var(--executive-website-regular-font-family);
    -webkit-tap-highlight-color: transparent;
  }

  h1 {
    ${H1};
    font-family: var(--executive-website-heading-font-family);
  }

  h2 {
    ${H2};
  }

  h3 {
    ${H3};
  }

  h4 {
    ${H4};
  }

  h5 {
    ${H5};
  }

  h6 {
    ${H6};
  }

  p {
    ${BodySmall};
  }

  .body-medium {
    ${BodyMedium};
  }

  .body-large {
    ${BodyLarge};
  }

  .body-small {
    ${BodySmall};
  }

  .caption {
    ${Caption};
  }

  .button {
    ${Button};
  }

  .menu-opened {
    overflow: hidden;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
    height: 100vh;
  }

  .grecaptcha-badge {
    bottom: 100px !important;
  }
`;
