import {
  breakpoint,
  sizes,
} from '@/themes/breakpoints';

export enum LoginStatesValues {
  LOADING = 'loading',
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated'
}

export type LoginStates = LoginStatesValues.AUTHENTICATED |
  LoginStatesValues.LOADING |
  LoginStatesValues.UNAUTHENTICATED;

export enum UnrestrictedEnpoints {
  CREATE = 'v1/auth/jwt/create/',
  REFRESH = 'v1/auth/jwt/refresh/',
}

export enum ThemeDirection {
  RTL = 'rtl',
  LTR = 'ltr'
}

/* eslint-disable sort-keys */
export const MediaQueries = {
  PHONE: `(max-width: ${sizes.tablet.minWidth - 1}px)`,
  TABLET: `${breakpoint.sm}`,
  DESKTOP: `${breakpoint.md}`,
  DESKTOP_LARGE: `${breakpoint.lg}`,
} as const;
