import { defineMessages } from 'react-intl';

export const globalMessages = defineMessages({
  'ar-AE': {
    defaultMessage: 'عربي',
    description: 'Arabic language label',
    id: 'global.arabic',
  },
  close: {
    defaultMessage: 'Close',
    description: 'aria label for close icon button',
    id: 'global.close',
  },
  defaultSeoTitle: {
    defaultMessage: 'Her Excellency Reem Al Hashimy - UAE Minister, Expo City Dubai CEO',
    description: 'Default value for page meta title tag',
    id: 'meta.title',
  },
  emailRequired: {
    defaultMessage: 'Incorrect e-mail address',
    description: 'input email validation error message',
    id: 'inputs.error.email',
  },
  en: {
    defaultMessage: 'English',
    description: 'English language label',
    id: 'global.english',
  },
  fieldMessageTooLong: {
    defaultMessage: 'This field length is too long',
    description: 'input length above max  ',
    id: 'inputs.error.max.label',
  },
  fieldRequired: {
    defaultMessage: 'This field is required',
    description: 'input required error message ',
    id: 'inputs.error.label',
  },
  metaTitlePart: {
    defaultMessage: 'Her Excellency Reem Al Hashimy',
    description: 'Default value for page meta title tag',
    id: 'meta.titlePart',
  },
  openSearch: {
    defaultMessage: 'Open search',
    description: 'aria label for open search page button',
    id: 'global.openSearchLabel',
  },
  patternImageAlt: {
    defaultMessage: 'Placeholder image with geometric pattern',
    description: 'alternative text for pattern image',
    id: 'global.patetrnImageAlternativeText',
  },
  readMore: {
    defaultMessage: 'Read more',
    description: 'Read more button message',
    id: 'global.readMore',
  },
  readTime: {
    defaultMessage: '{time}min read',
    description: 'Article reading time',
    id: 'global.readTime',
  },
  search: {
    defaultMessage: 'Search',
    description: 'aria label for search button',
    id: 'global.searchLabel',
  },
  selectSortOption: {
    defaultMessage: 'Select a sorting option',
    description: 'Aria label for sort option select in vision page',
    id: 'vision.sortSelectLabel',
  },
  selectTopic: {
    defaultMessage: 'Select a topic',
    description: 'Aria label for topic select in vision page',
    id: 'vision.topicSelectLabel',
  },
  share: {
    defaultMessage: 'Share',
    description: 'Share title on article page',
    id: 'global.share',
  },
  viewMore: {
    defaultMessage: 'View More',
    description: 'View more button message',
    id: 'homepage.moments.viewMore',
  },
});
