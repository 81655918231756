import { defineMessages } from 'react-intl';

export const navigationMessages = defineMessages({
  about: {
    defaultMessage: 'About',
    description: 'about link in navigation',
    id: 'navigation.about',
  },
  allRightsReserved: {
    defaultMessage: 'All rights reserved',
    description: 'info in the footer',
    id: 'navigation.allRightsReserved',
  },
  book: {
    defaultMessage: 'Book',
    description: 'book link in navigation',
    id: 'navigation.book',
  },
  contact: {
    defaultMessage: 'Contact',
    description: 'contact link in navigation',
    id: 'navigation.contact',
  },
  home: {
    defaultMessage: 'Home',
    description: 'Home page breadcrumb',
    id: 'navigation.home',
  },
  inTheNews: {
    defaultMessage: 'In the news',
    description: 'in the news (media) link in navigation',
    id: 'navigation.inTheNews',
  },
  legal: {
    defaultMessage: 'Legal',
    description: 'legal link in navigation',
    id: 'navigation.legal',
  },
  moments: {
    defaultMessage: 'Moments',
    description: 'moments link in navigation',
    id: 'navigation.moments',
  },
  pressPack: {
    defaultMessage: 'Press Pack',
    description: 'press pack link in navigation',
    id: 'navigation.pressPack',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
    description: 'privacy policy link in navigation',
    id: 'navigation.privacyPolicy',
  },
  search: {
    defaultMessage: 'Search',
    description: 'search page title',
    id: 'navigation.search',
  },
  vision: {
    defaultMessage: 'Vision',
    description: 'vision link in navigation',
    id: 'navigation.vision',
  },
});
