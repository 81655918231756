import {
  ElementType,
  ReactElement,
} from 'react';

import { Theme } from '@/themes/themes';

export enum ButtonVariant {
  PRIMARY = 'primary',
  PRIMARY_INVERTED = 'primary inverted',
  SECONDARY = 'secondary',
  GHOST = 'ghost',
  GHOST_INVERTED = 'ghost inverted'
}
export interface CustomButtonProps<T extends ElementType> {
  variant: ButtonVariant;
  label?: string;
  type?: 'button' | 'submit';
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  isDisabled?: boolean;
  renderAs?: T;
}

export interface ButtonThemeProps {
  variant: ButtonVariant;
  isIconButton?: boolean;
  theme: Theme;
  isHover?: boolean;
  isDisabled?: boolean;
}
