import { IconProps } from './Icon.types';

export const ArrowExtend = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M6.63311 18.0711H12.6062V19.0711H5.53516C5.40255 19.0711 5.27537 19.0184 5.1816 18.9247C5.08784 18.8309 5.03516 18.7037 5.03516 18.5711V11.5H6.03516L6.03516 17.2548L16.3864 6.90358L10.5237 6.90358V5.90358L17.3864 5.90358L17.3877 5.90234L17.3889 5.90358L17.5947 5.90358C17.7273 5.90358 17.8545 5.95626 17.9483 6.05003C18.042 6.14379 18.0947 6.27097 18.0947 6.40358V6.60941L18.0948 6.60945L18.0947 6.60949V13.4746H17.0947V7.60949L6.63311 18.0711Z"
    />
  </svg>
);
