import { IconProps } from './Icon.types';

export const ExternalLink = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5835 5.32812L18.5014 5.39354L18.5663 11.3273"
      stroke={fill}
      strokeLinejoin="round"
    />
    <path
      d="M10.4248 12.875C10.2295 13.0702 10.2295 13.3868 10.4248 13.5821C10.62 13.7773 10.9366 13.7773 11.1319 13.5821L10.4248 12.875ZM18.3248 4.97492L10.4248 12.875L11.1319 13.5821L19.0319 5.68203L18.3248 4.97492Z"
      fill={fill}
    />
    <path
      d="M9.64972 5.32812H4.00684V19.9996H18.6783V14.3567"
      stroke={fill}
      strokeLinejoin="round"
    />
  </svg>
);
