import { IconProps } from './Icon.types';

export const Dot = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="13"
      r="1"
      fill={fill}
    />
  </svg>
);
