import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { HeaderWrapper } from '@/core/components/HeaderWrapper';
import { routePaths } from '@/core/constants/routes';
import { IconButton } from '@/core/components/IconButton';
import { ButtonVariant } from '@/core/components/Button/Button.types';
import { IconButtonSizes } from '@/core/components/IconButton/IconButton.types';
import { useGlobalContext } from '@/core/contexts/GlobalContext/GlobalContext';
import { NavigationItem } from '@/core/interfaces/common';
import {
  Close,
  Hamburger,
  Search,
} from '@/core/components/Icons';
import { globalMessages } from '@/core/messages/global.messages';

import {
  ButtonsWrapper,
  LogoWrapper,
  SearchWrapper,
  StickyHeader,
  StyledLogo,
  TopBar,
  Wrapper,
} from './Header.styles';
import { Navigation } from './Navigation/Navigation';

type HeaderProps = {
  hideNavigation?: boolean;
  navigationItems: Array<NavigationItem>;
}

export const Header = ({
  hideNavigation,
  navigationItems,
}: HeaderProps) => {
  const { isTabletOrAbove } = useGlobalContext();
  const {
    events,
    route,
  } = useRouter();
  const { formatMessage } = useIntl();

  const isSearchRoute = useMemo(() => route === routePaths.search, [route]);

  const [
    isMobileMenuOpen,
    setIsMobileMenuOpen,
  ] = useState(isTabletOrAbove);

  useEffect(() => {
    if (!isTabletOrAbove) {
      setIsMobileMenuOpen(false);
    }
  }, [isTabletOrAbove]);

  useEffect(() => {
    if (!isTabletOrAbove && isMobileMenuOpen) {
      document.body.classList.add('menu-opened');
    } else {
      document.body.classList.remove('menu-opened');
    }
  }, [
    isMobileMenuOpen,
    isTabletOrAbove,
  ]);

  useEffect(() => {
    const closeMobileMenu = () => setIsMobileMenuOpen(false);

    events.on('routeChangeComplete', closeMobileMenu);

    return () => events.off('routeChangeComplete', closeMobileMenu);
  }, []);

  const toggleNavigation = () => setIsMobileMenuOpen(prevValue => !prevValue);

  const mobileButton = useMemo(
    () => (isMobileMenuOpen ? <Close /> : <Hamburger />),
    [isMobileMenuOpen]
  );

  const searchButton = (
    <IconButton
      renderAs={Link}
      icon={<Search />}
      size={IconButtonSizes.SMALL}
      variant={ButtonVariant.SECONDARY}
      ariaLabel={formatMessage(globalMessages.openSearch)}
      href={routePaths.search}
    />
  );

  return (
    <StickyHeader $isMobileMenuOpen={isMobileMenuOpen}>
      <TopBar
        percentageWidth={100}
        height={8}
      />
      <HeaderWrapper>
        <Wrapper $isMobileMenuOpen={isMobileMenuOpen}>
          <LogoWrapper>
            <Link href={routePaths.home}>
              <StyledLogo />
            </Link>
            {!hideNavigation && !isTabletOrAbove && (
              <ButtonsWrapper>
                {!isMobileMenuOpen && !isSearchRoute && searchButton}
                <IconButton
                  variant={ButtonVariant.SECONDARY}
                  size={IconButtonSizes.SMALL}
                  icon={mobileButton}
                  onClick={toggleNavigation}
                  ariaLabel="menu"
                />
              </ButtonsWrapper>
            )}
          </LogoWrapper>
          {!hideNavigation && (isTabletOrAbove || isMobileMenuOpen) && (
            <Navigation navigationItems={navigationItems} />
          )}
          {(!hideNavigation && isTabletOrAbove && !isSearchRoute) && (
            <SearchWrapper>
              {searchButton}
            </SearchWrapper>
          )}
          {!isMobileMenuOpen && isSearchRoute && (
            <SearchWrapper>
              <IconButton
                renderAs={Link}
                icon={<Close />}
                size={IconButtonSizes.SMALL}
                variant={ButtonVariant.SECONDARY}
                ariaLabel={formatMessage(globalMessages.close)}
                href={routePaths.home}
              />
            </SearchWrapper>
          )}
        </Wrapper>
      </HeaderWrapper>
    </StickyHeader>
  );
};
