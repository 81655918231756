import { ReactNode } from 'react';
import { DefaultSeo } from 'next-seo';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import {
  ContentWrapper,
  PageWrapper,
} from '@/core/components/Layout/Layout.styles';
import { globalMessages } from '@/core/messages/global.messages';
import { Navigation } from '@/core/interfaces/common';

import { getPageLink } from '@/utils/helpers';

import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';

interface LayoutProps {
  children: ReactNode;
  hideFooter?: boolean;
  hideNavigation?: boolean;
  navigation: Navigation;
}

export const Layout = ({
  children,
  hideFooter,
  hideNavigation,
  navigation,
}: LayoutProps) => {
  const { formatMessage } = useIntl();
  const {
    asPath,
    locale,
    defaultLocale,
  } = useRouter();

  return (
    <PageWrapper>
      <DefaultSeo
        defaultTitle={formatMessage(globalMessages.defaultSeoTitle)}
        openGraph={{
          locale: locale || defaultLocale || 'ar-AE',
          type: 'website',
          url: getPageLink(asPath, locale || defaultLocale || 'ar-AE'),
        }}
        titleTemplate={`%s | ${formatMessage(globalMessages.metaTitlePart)}`}
      />
      <Header
        hideNavigation={hideNavigation}
        navigationItems={navigation?.header}
      />

      <ContentWrapper>
        {children}
      </ContentWrapper>

      {!hideFooter && <Footer navigationItems={navigation?.footer} />}
    </PageWrapper>
  );
};
