import styled from 'styled-components';

import { IconButtonProps } from './IconButton';
import { getButtonTheme } from '../Button/Button.helpers';
import { getSizeRelatedProperties } from './IconButton.helpers';

export const StyledIconButton = styled.button<Omit<IconButtonProps, 'icon'>>`
  position: relative;
  z-index: 2;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: none;

  &:not(:disabled) {
    &:active {
      opacity: 0.88;
    }
    cursor: pointer;
  }
  ${({ size }) => size && getSizeRelatedProperties(size)}
  ${({
    theme,
    variant,
  }) => getButtonTheme({
    isIconButton: true,
    theme,
    variant,
  })}

  &[data-userway-s3-2-styled="true"] {
    path {
      fill: var(--user-way-dark-contrast-color);
    }
  }
`;
