import { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { useEffect } from 'react';
import { NextPage } from 'next';

import { Layout } from '@/core/components/Layout';
import {
  MessagesObj,
  Translation,
} from '@/core/components/Translation';
import { useTheme } from '@/core/hooks/useTheme';
import { GlobalContextProvider } from '@/core/contexts/GlobalContext/GlobalContext';
import { UserWayScript } from '@/core/components/UserWayScript';
import { Navigation } from '@/core/interfaces/common';

import { GlobalStyle } from '@/themes/globalStyles';

type ExtendedAppProps = AppProps<{ navigation: Navigation; messages: MessagesObj }> & {
  Component: NextPage & { hideFooter?: boolean; hideNavigation?: boolean };
}

const MyApp = ({
  Component,
  pageProps: {
    messages,
    navigation,
    ...pageProps
  },
}: ExtendedAppProps) => {
  const theme = useTheme();

  useEffect(() => {
    const checkIfBodyScrolled = () => {
      if (window.scrollY > 0) {
        document.body.classList.add('scrolled');
      } else {
        document.body.classList.remove('scrolled');
      }
    };

    checkIfBodyScrolled();

    window.addEventListener('scroll', checkIfBodyScrolled);

    return () => {
      window.removeEventListener('scroll', checkIfBodyScrolled);
    };
  }, []);

  return (
    <>
      <UserWayScript />
      <ThemeProvider theme={{ ...theme }}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1"
          />
        </Head>
        <GlobalStyle />
        <Translation serverMessages={messages}>
          <GlobalContextProvider>
            <Layout
              hideFooter={Component.hideFooter}
              hideNavigation={Component.hideNavigation}
              navigation={navigation}
            >
              <Component {...pageProps} />
            </Layout>
          </GlobalContextProvider>
        </Translation>
      </ThemeProvider>
    </>
  );
};

export default MyApp;
