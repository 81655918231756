import { IconProps } from './Icon.types';

export const Right = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.25 18.75L15.75 12.25L9.25 5.75"
      stroke={fill}
      strokeLinejoin="round"
    />
  </svg>
);
