import React, {
  ElementType,
  HTMLProps,
} from 'react';

import { StyledLinkButton } from './LinkButton.styles';
import { CustomLinkButtonProps } from './LinkButton.types';

export type LinkButtonProps<T extends ElementType = 'button'> = CustomLinkButtonProps<T> & HTMLProps<T>;

export const LinkButton = <T extends ElementType = 'button'>({
  onClick,
  label,
  isActive,
  renderAs,
  ...props
}: LinkButtonProps<T>) => (
  <StyledLinkButton
    onClick={onClick}
    $isActive={isActive}
    as={renderAs as any}
    {...props}
  >
    {label}
  </StyledLinkButton>
  );

