import { IconProps } from './Icon.types';

export const Download = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M8 12L12 16.232L16 12.041M12 5V15.5M6 19H18"
    />
  </svg>
);
