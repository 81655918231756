import styled, { css } from 'styled-components';

import { ThemeDirection } from '@/core/constants/constants';

import { breakpoint } from '@/themes/breakpoints';

import { BarProps } from './Bar';

export const StyledBar = styled.div<BarProps>`
  display: flex;
  ${({
    percentageWidth,
    fixedWidth,
  }) => {
    if (percentageWidth) {
      return css`width: ${percentageWidth}%;`;
    }

    if (fixedWidth) {
      return css`
        width: 160px;

        @media ${breakpoint.sm} {
          width: 256px;
        }
      `;
    }

    return css`width: 100%;`;
  }}
  width: ${({ percentageWidth }) => percentageWidth}%;
  height: ${({ height }) => height}px;
  background: ${({ theme: { direction } }) => (direction === ThemeDirection.LTR ?
    'linear-gradient(90deg, rgba(186, 218, 255, 1) 0%, rgba(186, 218, 255, 1) 56%, rgba(189, 246, 232, 1) 56%, rgba(189, 246, 232, 1) 78%, rgba(250, 237, 190, 1) 78%, rgba(250, 237, 190, 1) 100%)' :
    'linear-gradient(90deg, rgba(250, 237, 190, 1) 0%, rgba(250, 237, 190, 1) 22%, rgba(189, 246, 232, 1) 22%, rgba(189, 246, 232, 1) 44%, rgba(186, 218, 255, 1) 44%, rgba(186, 218, 255, 1) 100%)')};
`;
