import { IconProps } from './Icon.types';

export const Down = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M6 9L11.7929 14.7929C12.1834 15.1834 12.8166 15.1834 13.2071 14.7929L19 9"
    />
  </svg>
);
