import { IconProps } from './Icon.types';

export const X = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon/Twitter Outline">
      <path
        id="Icon"
        d="M13.7144 10.6226L20.4161 3H18.8282L13.0066 9.6172L8.36024 3H3L10.0277 13.0072L3 21H4.58785L10.7319 14.0104L15.6398 21H21L13.7144 10.6226ZM11.5389 13.0951L10.8258 12.098L5.16047 4.17134H7.59974L12.1732 10.5708L12.8833 11.5679L18.8275 19.8858H16.3882L11.5389 13.0951Z"
        fill={fill}
      />
    </g>
  </svg>
);
