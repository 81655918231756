import styled, { css } from 'styled-components';
import { ReactElement } from 'react';

import { BodySmall } from '@/core/components/Typography/Typography.styles';

import { getButtonTheme } from './Button.helpers';
import { ButtonVariant } from './Button.types';

export const StyledButton = styled.button<{ $startIcon: ReactElement; $endIcon: ReactElement; $variant: ButtonVariant }>`
  ${BodySmall}
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 40px;
  padding: ${({
    $startIcon,
    $endIcon,
  }) => ($startIcon || $endIcon ? '11px 36px' : '15px 36px')};
  ${({
    $variant,
    $startIcon,
    $endIcon,
  }) => $variant === ButtonVariant.GHOST && ($startIcon || $endIcon) && css`
    padding: 0;
    `};
  ${({
    $startIcon,
    $endIcon,
  }) => !!$startIcon && !$endIcon && css`
    padding-inline-end: 32px;
    `}
  ${({
    $startIcon,
    $endIcon,
  }) => !$startIcon && !!$endIcon && css`
    padding-inline-start: 32px;
    `}
  gap: 12px;
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.semiBold};
  font-family: inherit;
  line-height: 24px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;

  &:not(:disabled) {
    &:active {
      opacity: 0.88;
    }
    cursor: pointer;
  }
  ${({
    theme,
    $variant,
  }) => getButtonTheme({
    theme,
    variant: $variant,
  })}
`;

export const IconWrapper = styled.div<{ isGhost: boolean }>`
  display: flex;
  ${({ isGhost }) => isGhost && css`
    border: 1px solid ${({ theme }) => theme.colors.alpha.black16};
    border-radius: 40px;
    padding: 11px;
  `}

  &[data-userway-s3-2-styled="true"] {
    path {
      fill: var(--user-way-dark-contrast-color);
    }
  }
`;
