import { IconProps } from './Icon.types';

export const ArrowRight = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M13.8536 17.3536L18.8536 12.3536C18.9473 12.2598 19 12.1326 19 12C19 11.8674 18.9473 11.7403 18.8536 11.6465L13.8536 6.64648L13.1464 7.35359L17.2929 11.5H5.5V12.5H17.2929L13.1464 16.6465L13.8536 17.3536Z"
    />
  </svg>
);
