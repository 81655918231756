import { IconProps } from './Icon.types';

export const ArrowBottom = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M12.5 17.2929L12.5 5.5H11.5L11.5 17.2929L7.35359 13.1464L6.64648 13.8536L11.6465 18.8536C11.7403 18.9473 11.8674 19 12 19C12.1326 19 12.2598 18.9473 12.3536 18.8536L17.3536 13.8536L16.6465 13.1464L12.5 17.2929Z"
    />
  </svg>
);
