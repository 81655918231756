import {
  Color,
  Keyword,
} from '@/core/interfaces/common';

export const isBrowser = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const isLocaleRTL = (locale: string | undefined) => {
  if (!locale) {
    return false;
  }

  return [
    'ar',
    'arc',
    'dv',
    'fa',
    'ha',
    'he',
    'khw',
    'ks',
    'ku',
    'ps',
    'ur',
    'yi',
  ].includes(locale.split('-')[0]);
};

export const importLocaleMessages = async (locale: string | undefined) => {
  if (!locale) {
    return false;
  }

  try {
    return (await import(`@/lang/compiled/${locale}.json`)).default;
  } catch (e) {
    return undefined;
  }
};

export const getBackendImage = (image?: string, placeholderColor?: Color) => {
  if (process.env.NEXT_PUBLIC_ENV === 'development' && image && !image.includes('http')) {
    return `${process.env.NEXT_PUBLIC_BACKEND_URL}${image}`;
  }

  if (image) {
    return image;
  }

  return `/assets/images/vectorBackgrounds/${placeholderColor || 'grey'}.svg`;
};

interface FieldsObject {
  [key: string]: string;
}

export const addRequestFieldsToObject = (fieldsArray: Array<string>) => fieldsArray
  .reduce<FieldsObject>(
    (acc, item, index) => ({
      ...acc,
      [`fields[${index}]`]: item,
    }),
    {}
  );

export const getPageLink = (path: string, locale: string) => `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${locale}${path}`;

export const getKeywordsProps = (metaKeywords?: Array<Keyword>) => {
  const keywords = metaKeywords?.map(keyword => keyword.keyword) || [];

  return keywords.length ?
    {
      additionalMetaTags: [
        {
          name: 'keywords',
          content: keywords.join(', '), // eslint-disable-line sort-keys
        },
      ],
    } :
    {};
};

export const isOutsideLink = (link: string) => link.includes('http') || link.includes('www');
