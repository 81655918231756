import { useTheme } from 'styled-components';

import { ThemeDirection } from '@/core/constants/constants';

import { theme } from '@/themes/main';

import { LogoEN } from './LogoEN';
import { LogoAR } from './LogoAR';
import { LogoProps } from './Logo.types';

export const Logo = ({
  fill = theme.colors.black,
  ...props
}: LogoProps) => {
  const { direction } = useTheme();

  return (
    <div {...props}>
      { direction === ThemeDirection.LTR ? <LogoEN fill={fill} /> : <LogoAR fill={fill} /> }
    </div>
  );
};
