import {
  ElementType,
  ReactElement,
} from 'react';

import { theme } from '@/themes/main';

import { ButtonVariant } from '../Button/Button.types';

export enum IconButtonSizes {
  SMALL = 'small',
  REGULAR = 'regular',
  HUGE = 'huge',
}

export interface CustomIconButtonProps {
  variant: ButtonVariant;
  type?: 'button' | 'submit';
  icon: ReactElement;
  size?: IconButtonSizes;
  isDisabled?: boolean;
  iconColor?: typeof theme.colors[keyof typeof theme.colors];
  renderAs?: ElementType;
  href?: string;
  target?: string;
  ariaLabel: string;
}
