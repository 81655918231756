import { IconProps } from './Icon.types';

export const Close = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon/Close">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 12L5.64648 17.6464L6.35359 18.3535L12 12.7071L17.6465 18.3535L18.3536 17.6464L12.7071 12L18.3535 6.35359L17.6464 5.64648L12 11.2929L6.35364 5.64648L5.64653 6.35359L11.2929 12Z"
        fill={fill}
      />
    </g>
  </svg>
);
