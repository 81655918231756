export const routePaths = {
  about: '/about',
  book: '/book',
  contact: '/contact',
  error: '/404',
  home: '/',
  inTheNews: '/in-the-news',
  legal: '/legal',
  moments: '/moments',
  pressPack: '/press-pack',
  privacyPolicy: '/privacy-policy',
  search: '/search',
  vision: '/vision',
};
