import styled, { css } from 'styled-components';

import {
  breakpoint,
  MediaBreakpoint,
  sizes,
} from '@/themes/breakpoints';

import { ColumnProps } from './Grid.types';

const columnBreakpoint = (
  mediaBreakpoint: MediaBreakpoint,
  offset: number | string = 'auto',
  value: number
) => css`
  @media ${mediaBreakpoint} {
    grid-column: ${offset} / span ${value};
  }
`;

export const margins = (margin: number) => `
  margin-right: max(env(safe-area-inset-right), ${margin}px);
  margin-left: max(env(safe-area-inset-left), ${margin}px);
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${sizes.phone.columns}, minmax(0, 1fr));
  column-gap: ${sizes.phone.gap}px;
  margin: 0 ${sizes.phone.margin}px;

  @media ${breakpoint.sm} {
    grid-template-columns: repeat(${sizes.tablet.columns}, minmax(0, 1fr));
    column-gap: ${sizes.tablet.gap}px;
    ${margins(sizes.tablet.margin)};
  }

  @media ${breakpoint.md} {
    grid-template-columns: repeat(${sizes.desktop.columns}, minmax(0, 1fr));
    column-gap: ${sizes.desktop.gap}px;
    ${margins(sizes.desktop.margin)};
  }

  @media ${breakpoint.lg} {
    column-gap: ${sizes.desktopLarge.gap}px;
    ${margins(sizes.desktopLarge.margin)};
  }
`;

export const Column = styled.div<ColumnProps>`
  grid-column: -1 / 1;
  ${({
    xs,
    offsetXs,
  }) => xs && columnBreakpoint(breakpoint.xs, offsetXs, xs)};
  ${({
    sm,
    offsetSm,
  }) => sm && columnBreakpoint(breakpoint.sm, offsetSm, sm)};
  ${({
    md,
    offsetMd,
  }) => md && columnBreakpoint(breakpoint.md, offsetMd, md)};
  ${({
    lg,
    offsetLg,
  }) => lg && columnBreakpoint(breakpoint.lg, offsetLg, lg)};
`;
