import { css } from 'styled-components';

import { breakpoint } from '@/themes/breakpoints';

export const H1 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.3px;

  @media ${breakpoint.sm} {
    font-size: 48px;
    line-height: 116.667%;
  }

  @media ${breakpoint.md} {
    font-size: 80px;
    line-height: 105%;
  }
`;

export const H2 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: 36px;
  line-height: 111.111%;
  letter-spacing: 0.3px;

  @media ${breakpoint.sm} {
    font-size: 40px;
    line-height: 120%;
  }

  @media ${breakpoint.md} {
    font-size: 48px;
    line-height: 116.667%;
  }
`;

export const H3 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: 32px;
  line-height: 125%;
  letter-spacing: 0.3px;

  @media ${breakpoint.md} {
    font-size: 40px;
    line-height: 120%;
  }
`;

export const H4 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: 28px;
  line-height: 114.286%;
  letter-spacing: 0.3px;

  @media ${breakpoint.md} {
    font-size: 32px;
    line-height: 125%;
  }
`;

export const H5 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.3px;

  @media ${breakpoint.sm} {
    font-size: 24px;
    line-height: 133.333%;
  }

  @media ${breakpoint.md} {
    line-height: 116.667%;
  }
`;

export const H6 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: 18px;
  line-height: 133.33%;
  letter-spacing: 0.3px;

  @media ${breakpoint.sm} {
    font-size: 20px;
    line-height: 140%;
  }

  @media ${breakpoint.md} {
    font-size: 18px;
    line-height: 133.333%;
  }
`;

export const Subhead = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.semiBold};
  font-size: 14px;
  line-height: 114.286%;
  letter-spacing: 0.3px;

  @media ${breakpoint.md} {
    font-weight: ${({ theme: { fontWeight } }) => fontWeight.medium};
    font-size: 16px;
    line-height: 175%;
  }
`;

export const BodyLarge = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.semiBold};
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.3px;

  @media ${breakpoint.md} {
    font-weight: ${({ theme: { fontWeight } }) => fontWeight.medium};
    font-size: 18px;
    line-height: 177.778%;
  }
`;

export const BodyMedium = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: 16px;
  line-height: 175%;
  letter-spacing: 0.3px;

  @media ${breakpoint.md} {
    line-height: 200%;
  }
`;

export const BodySmall = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: 14px;
  line-height: 142.857%;
  letter-spacing: 0.3px;

  @media ${breakpoint.sm} {
    line-height: 171.429%;
  }
`;

export const Caption = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: 12px;
  line-height: 133.333%;
  letter-spacing: 0.3px;
`;

export const Button = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
  font-size: 14px;
  line-height: 171.429%;
  letter-spacing: 2px;
`;

export const Quote = css`
  font-style: italic;
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: 36px;
  font-family: var(--executive-website-heading-font-family);
  line-height: 122.222%;
  letter-spacing: 0.3px;

  @media ${breakpoint.sm} {
    font-size: 48px;
    line-height: 116.667%;
  }

  @media ${breakpoint.md} {
    font-size: 64px;
    line-height: 112.5%;
  }
`;
