import { css } from 'styled-components';

import { IconButtonSizes } from './IconButton.types';

export const getSizeRelatedProperties = (size: IconButtonSizes) => {
  switch (size) {
    case IconButtonSizes.SMALL:
      return css`
        border-radius: 20px;
        padding: 7px;
        gap: 16px;
      `;
    case IconButtonSizes.REGULAR:
      return css`
        border-radius: 28px;
        padding: 11px;
        gap: 16px;
      `;
    case IconButtonSizes.HUGE:
      return css`
        border-radius: 40px;
        padding: 23px;
        gap: 16px;
      `;
    default:
      return css``;
  }
};
