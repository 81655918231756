import styled from 'styled-components';

import { breakpoint } from '@/themes/breakpoints';

export const Wrapper = styled.section`
  border-top: 1px solid ${({ theme: { colors } }) => colors.neutral[700]};
  padding: 16px 0;
  list-style: none;

  @media ${breakpoint.sm} {
    padding: 64px 0;
  }
`;

export const Links = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  a {
    padding: 10px 0;
    color: ${({ theme: { colors } }) => colors.neutral[100]};
    text-decoration: none;
  }

  @media ${breakpoint.sm} {
    flex-direction: row;
  }
`;
