import { IconProps } from './Icon.types';

export const ArrowLeft = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M10.1464 17.3536L5.14645 12.3536C5.05268 12.2598 5 12.1326 5 12C5 11.8674 5.05268 11.7403 5.14645 11.6465L10.1464 6.64648L10.8536 7.35359L6.70711 11.5H18.5V12.5H6.70711L10.8536 16.6465L10.1464 17.3536Z"
    />
  </svg>
);
