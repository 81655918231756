import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';

import { breakpoint } from '@/themes/breakpoints';

import {
  ArticleSlugs,
  GlobalContextProviderProps,
  GlobalContextState,
  WindowDimensions,
} from './GlobalContext.types';

const GlobalContext = createContext<GlobalContextState | undefined>(undefined);

export const GlobalContextProvider = ({ children }: GlobalContextProviderProps) => {
  const tabletOrAbove = useMediaQuery({ query: breakpoint.sm });
  const desktop = useMediaQuery({ query: breakpoint.md });

  useEffect(() => {
    setIsTabletOrAbove(tabletOrAbove);
  }, [tabletOrAbove]);

  useEffect(() => {
    setIsDesktop(desktop);
  }, [desktop]);

  const [
    isTabletOrAbove,
    setIsTabletOrAbove,
  ] = useState(false);

  const [
    isDesktop,
    setIsDesktop,
  ] = useState(false);

  const [
    isTouchDevice,
    setIsTouchDevice,
  ] = useState(false);

  const [
    articleSlugs,
    setArticleSlugs,
  ] = useState<ArticleSlugs>({});

  const [
    windowDimensions,
    setWindowDimentions,
  ] = useState<WindowDimensions>({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimentions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
      setIsTouchDevice(navigator?.maxTouchPoints > 0);
    };

    window.addEventListener('resize', handleWindowResize);

    handleWindowResize();

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        articleSlugs,
        isDesktop,
        isTabletOrAbove,
        isTouchDevice,
        setArticleSlugs,
        windowDimensions,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalContext provider');
  }

  return context;
};
