import { IconProps } from './Icon.types';

export const Search = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 20L15.9862 15.9791M18.2105 10.6053C18.2105 12.6223 17.4093 14.5567 15.983 15.983C14.5567 17.4093 12.6223 18.2105 10.6053 18.2105C8.58822 18.2105 6.65379 17.4093 5.22753 15.983C3.80127 14.5567 3 12.6223 3 10.6053C3 8.58822 3.80127 6.65379 5.22753 5.22753C6.65379 3.80127 8.58822 3 10.6053 3C12.6223 3 14.5567 3.80127 15.983 5.22753C17.4093 6.65379 18.2105 8.58822 18.2105 10.6053Z"
      stroke={fill}
      strokeLinecap="round"
    />
  </svg>
);
