import { IconProps } from './Icon.types';

export const Spinner = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="12.9995"
      width="2"
      height="6"
      rx="1"
      transform="rotate(-90 0 12.9995)"
      fill="#0D0D0D"
      fillOpacity="0.08"
    />
    <rect
      x="18"
      y="12.9995"
      width="2"
      height="6"
      rx="1"
      transform="rotate(-90 18 12.9995)"
      fill="#0D0D0D"
      fillOpacity="0.24"
    />
    <rect
      x="13.001"
      y="24"
      width="2"
      height="6"
      rx="1"
      transform="rotate(180 13.001 24)"
      fill="#0D0D0D"
      fillOpacity="0.24"
    />
    <rect
      x="13.001"
      y="6"
      width="2"
      height="6"
      rx="1"
      transform="rotate(180 13.001 6)"
      fill="#1C1D1E"
    />
    <rect
      x="4.22119"
      y="21.1924"
      width="2"
      height="6"
      rx="1"
      transform="rotate(-135 4.22119 21.1924)"
      fill="#0D0D0D"
      fillOpacity="0.16"
    />
    <rect
      x="16.9502"
      y="8.46338"
      width="2"
      height="6"
      rx="1"
      transform="rotate(-135 16.9502 8.46338)"
      fill="#1C1D1E"
    />
    <rect
      x="21.1924"
      y="19.7788"
      width="2"
      height="6"
      rx="1"
      transform="rotate(135 21.1924 19.7788)"
      fill="#0D0D0D"
      fillOpacity="0.24"
    />
    <rect
      x="8.46582"
      y="7.0498"
      width="2"
      height="6"
      rx="1"
      transform="rotate(135 8.46582 7.0498)"
      fill="#0D0D0D"
      fillOpacity="0.08"
    />
  </svg>
);

