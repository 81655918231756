import styled, { css } from 'styled-components';

import { IconButton } from '@/core/components/IconButton';
import { Bar } from '@/core/components/Bar';
import { ThemeDirection } from '@/core/constants/constants';
import { Logo } from '@/core/components/Logo';

import { breakpoint } from '@/themes/breakpoints';

export const Wrapper = styled.div<{ $isMobileMenuOpen: boolean }>`
  display: flex;
  flex-direction: ${({ $isMobileMenuOpen }) => ($isMobileMenuOpen ? 'column' : 'row')};
  justify-content: center;
  align-items: center;

  body.menu-opened & {
    height: 100%;
  }

  @media ${breakpoint.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 88px;
  transition: 0.4s;

  body.scrolled & {
    height: 64px;
  }
`;

export const StyledLogo = styled(Logo)`
  &[data-userway-s3-2-styled="true"] {
    path {
      fill: var(--user-way-dark-contrast-color);
    }
  }

  svg {
    height: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? 40 : 72)}px;
    transition: 0.2s;

    body.scrolled & {
      height: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? 31 : 56)}px;
    }
  }
`;

export const TopBar = styled(Bar)`
  display: block;
  transition: 0.2s;

  body.scrolled & {
    display: none;
  }
`;

export const MenuIconButton = styled(IconButton)`
  @media ${breakpoint.sm} {
    display: none;
  }
`;

export const StickyHeader = styled.header<{ $isMobileMenuOpen: boolean }>`
  position: fixed;
  top: 0;
  z-index: 4;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  ${({ $isMobileMenuOpen }) => $isMobileMenuOpen && css`
    height: 100vh;

    @media ${breakpoint.sm} {
      height: auto;
    }
  `}
`;

export const SearchWrapper = styled.div`
  padding-right: ${({ theme: { direction } }) => (direction === ThemeDirection.LTR ? 0 : '24px')};
  padding-left: ${({ theme: { direction } }) => (direction === ThemeDirection.LTR ? '24px' : 0)};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
`;
