import styled, { css } from 'styled-components';

import { BodySmall } from '@/core/components/Typography/Typography.styles';

export const StyledLinkButton = styled.button<{ $isActive: boolean }>`
  ${BodySmall}
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: none;
  padding: 0;
  background-color: transparent;
  color: ${({ theme: { colors } }) => colors.black};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-family: inherit;
  line-height: 24px;
  text-wrap: nowrap;
  text-decoration: none;
  letter-spacing: 0.3px;
  cursor: pointer;
  ${({ $isActive }) => $isActive && css`
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.black};
  `};

  &:hover {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.black};
  }

  &:not(:disabled) {
    &:active {
      opacity: 0.88;
    }
    cursor: pointer;
  }
`;
