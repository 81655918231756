import { IconProps } from './Icon.types';

export const Play = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.354 11.5742C18.6713 11.7694 18.6713 12.2306 18.354 12.4258L8.26205 18.6363C7.92891 18.8413 7.5 18.6016 7.5 18.2104L7.5 5.78956C7.5 5.3984 7.92891 5.15873 8.26205 5.36373L18.354 11.5742Z"
    />
  </svg>
);
