import { IconProps } from './Icon.types';

export const YT = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={size}
      height={size}
      fill={fill}
    />
    <path
      d="M20.6203 7.52959C20.5178 7.13697 20.3173 6.77898 20.0386 6.49147C19.76 6.20396 19.413 5.99701 19.0325 5.89133C17.6311 5.5 12 5.5 12 5.5C12 5.5 6.36887 5.5 4.96752 5.89133C4.587 5.99701 4.24005 6.20396 3.9614 6.49147C3.68275 6.77898 3.48217 7.13697 3.37975 7.52959C3.11809 9.00421 2.99111 10.5009 3.00048 12C2.99111 13.4991 3.11809 14.9958 3.37975 16.4704C3.48217 16.863 3.68275 17.221 3.9614 17.5085C4.24005 17.796 4.587 18.003 4.96752 18.1087C6.36887 18.5 12 18.5 12 18.5C12 18.5 17.6311 18.5 19.0325 18.1087C19.413 18.003 19.76 17.796 20.0386 17.5085C20.3173 17.221 20.5178 16.863 20.6203 16.4704C20.8819 14.9958 21.0089 13.4991 20.9995 12C21.0089 10.5009 20.8819 9.00421 20.6203 7.52959ZM10.2001 14.7857V9.21429L14.8734 12L10.2001 14.7857Z"
      fill="#ffffff"
    />
  </svg>
);
