import styled from 'styled-components';

import { breakpoint } from '@/themes/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme: { colors } }) => colors.neutral[700]};
  padding: 24px 0 40px;

  @media ${breakpoint.sm} {
    padding: 64px 0 69px;
  }
`;
