import Link from 'next/link';

import { NavigationItem } from '@/core/interfaces/common';

import { isOutsideLink } from '@/utils/helpers';

import {
  Links,
  Wrapper,
} from './Navigation.styles';

type NavigationProps = {
  navigationItems?: Array<NavigationItem>;
}

export const Navigation = ({
  navigationItems,
}: NavigationProps) => (
  <Wrapper>
    <Links>
      {navigationItems?.map(({
        id,
        link,
        name,
      }) => link && (
        <Link
          key={id}
          href={link}
          target={isOutsideLink(link) ? '_blank' : '_self'}
        >
          { name }
        </Link>
      ))}
    </Links>
  </Wrapper>
);
